import bg_1 from './1.jpg';
import bg_2 from './2.jpg';
import bg_3 from './3.jpg';
import bg_4 from './4.jpg';
import bg_5 from './5.jpg';
import bg_6 from './6.jpg';
import bg_7 from './7.jpg';
import bg_8 from './8.png';
import bg_9 from './9.jpg';
import bg_10 from './10.jpg';
import bg_11 from './11.jpg';
import bg_12 from './12.jpg';
import bg_13 from './13.jpg';

export const banners = [bg_1, bg_2, bg_3, bg_4, bg_5, bg_6, bg_7, bg_7, bg_8, bg_9, bg_10, bg_11, bg_12, bg_13];
